<template>
  <div>
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :sm="24"
        :md="12"
        :lg="12"
        :xl="12">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.skill')}} </span>
            <el-input
              :placeholder="$t('message.skill')"
              v-model="form.skill"
              size="medium"
              :class="mode ? 'input__day' : 'input__night'"
            ></el-input>
          </div>
        </el-col>
        <!-- end col -->
        <el-col :sm="24"
        :md="12"
        :lg="12"
        :xl="12">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.degree')}} </span>
            <el-select
              class="w-100"
              v-model="form.level"
              :placeholder="$t('message.degree')"
              size="medium"
              :class="mode ? 'input__day' : 'input__night'"
            >
              <el-option label="Начальный" value="Начальный"></el-option>
              <el-option label="Элементарный" value="Элементарный"></el-option>
              <el-option label="Средний" value="Средний"></el-option>
              <el-option
                label="Средне-продвинутый"
                value="Средне-продвинутый"
              ></el-option>
              <el-option label="Продвинутый" value="Продвинутый"></el-option>
              <el-option 
                label="В совершенстве"
                value="В совершенстве"
              ></el-option>
            </el-select>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer my-style-modal">
      <el-button type="success" size="medium" @click="submit()" >
        {{$t("message.save")}}
      </el-button>
      <el-button type="warning" size="medium" @click="close()" >
        {{$t("message.close")}}
      </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      addStaffSkill: "profile/addStaffSkill",
      mode: "MODE"

    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      if (this.form.staff_id) {
        this.addStaffSkill(this.form)
          .then((res) => {
            this.$alert(res);
            if (res.status == 201) {
              this.parent().fetchStaffSkill();
              this.close();
            }
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    close() {
      this.clearForm();
      this.parent().closeSkillDialog();
    },
    parent() {
      return this.$parent.$parent;
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
    },
  },
};
</script>
