<template>
    <div v-can="'ProfileController@getStaffChildren'">
        <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title">{{$t('message.children')}}</div>
                <i class="el-icon-plus" @click="childrenCreate = true" v-can="'ProfileController@addStaffChildren'"></i>
            </div>
            <div
                class="workers-info madal-info-workers p-5 pt-2 pb-2"
                v-loading="loadChildren"
            >
                <table class="w-100" :class="mode ? 'table__myday' : 'table__mynight'">
                    <tr>
                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.nameFull')}}</strong>
                        </td>
                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.gender')}}</strong>
                        </td>
                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">{{$t('message.nation')}}</strong>
                        </td>
                        <td>
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">
                                {{$t('message.date_of_birth')}}
                            </strong>
                        </td>
                        <td colspan="2">
                            <strong class="mr-1 font-bold" :class="mode ? 'text__day2' : 'text__night2'">
                                {{$t('message.education')}}
                            </strong>
                        </td>
                    </tr>
                    <tr
                        v-for="(child, index) in staffChildren"
                        :key="'child-' + index"
                    >
                        <td>
                            <p :class="mode ? 'text__day2' : 'text__night2'"
                                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
                            >
                                <span> {{ child.full_name }} </span>
                            </p>
                        </td>

                        <td>
                            <p :class="mode ? 'text__day2' : 'text__night2'"
                                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
                            >
                                <span
                                    >{{ child.gender ? child.gender.name : "" }}
                                </span>
                            </p>
                        </td>

                        <td>
                            <p :class="mode ? 'text__day2' : 'text__night2'"
                                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
                            >
                                <span
                                    >{{
                                        child.nation
                                            ? child.nation.nationality
                                            : ""
                                    }}
                                </span>
                            </p>
                        </td>

                        <td>
                            <p :class="mode ? 'text__day2' : 'text__night2'"
                                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
                            >
                                <span>{{ child.date_of_birth }}</span>
                            </p>
                        </td>

                        <td>
                            <p :class="mode ? 'text__day2' : 'text__night2'"
                                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
                            >
                                <span>{{ child.education_level }}</span>
                            </p>
                        </td>

                        <td class="text-right">
                            <el-row>
                                <el-button
                                    type="primary"
                                    icon="el-icon-edit"
                                    circle
                                    size="small"
                                    @click="editStaffChildren(child)"
                                    v-can="'ProfileController@updateStaffChildren'"
                                ></el-button>

                                <el-button
                                    type="danger"
                                    icon="el-icon-delete"
                                    circle
                                    size="small"
                                    @click="deleteChildren(child)"
                                    v-can="'ProfileController@deleteStaffChildren'"
                                ></el-button>
                            </el-row>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <!-- start Modal  -->
        <el-dialog
          :class="mode ? 'dialog__myday' : 'dialog__mynight'"
            class="ichki_modal"
            title="Добавить Дети"
            :visible.sync="childrenCreate"
            width="60%"
            center
        >
            <el-form ref="form" :model="form">
                <el-row :gutter="20">
                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.nameFull')}}</span>
                            <el-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :placeholder="$t('message.nameFull') "
                                v-model="form.full_name"
                                size="medium"
                            ></el-input>
                        </div>
                    </el-col>
                    <!-- end col -->
                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.gender") }}
                            </span>
                            <select-gender
                                :size="'medium'"
                                :id="form.gender_id"
                                v-model="form.gender_id"
                            >
                            </select-gender>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{$t('message.education')}}
                            </span>
                            <el-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :placeholder="$t('message.education') "
                                v-model="form.education_level"
                                size="medium"
                            ></el-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.nation") }}
                            </span>
                            <select-nation
                                :size="'medium'"
                                :id="form.nation_id"
                                v-model="form.nation_id"
                            >
                            </select-nation>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="24">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{$t('message.date_of_birth')}}
                            </span>
                            <el-date-picker
                            :class="mode ? 'input__day' : 'input__night'"
                                v-model="form.date_of_birth"
                                type="date"
                                :placeholder="$t('message.date_of_birth')"
                                size="medium"
                                class="w-100"
                            >
                            </el-date-picker>
                        </div>
                    </el-col>
                    <!-- end col -->
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="submit()" >
                     {{$t("message.save")}}
                </el-button>
                <el-button type="warning" size="medium" @click="close()" >
                    {{$t("message.close")}}
                </el-button>
            </span>
        </el-dialog>

        <el-dialog
          :class="mode ? 'dialog__myday' : 'dialog__mynight'"
            class="ichki_modal"
            title=" Изменить Дети"
            :visible.sync="childrenUpdate"
            width="60%"
            center
            @open="open('childrenUpdate')"
        >
            <el-form ref="form" :model="form">
                <el-row :gutter="20">
                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.nameFull')}}</span>
                            <el-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :placeholder="$t('message.nameFull') "
                                v-model="form.full_name"
                                size="medium"
                            ></el-input>
                        </div>
                    </el-col>
                    <!-- end col -->
                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.gender") }}
                            </span>
                            <select-gender
                                :size="'medium'"
                                :id="form.gender_id"
                                v-model="form.gender_id"
                            >
                            </select-gender>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{$t('message.education')}}
                            </span>
                            <el-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :placeholder="$t('message.education') "
                                v-model="form.education_level"
                                size="medium"
                            ></el-input>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.nation") }}
                            </span>
                            <select-nation
                                :size="'medium'"
                                :id="form.nation_id"
                                v-model="form.nation_id"
                            >
                            </select-nation>
                        </div>
                    </el-col>
                    <!-- end col -->

                    <el-col :span="24">
                        <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{$t('message.date_of_birth')}}
                            </span>
                            <el-date-picker
                            :class="mode ? 'input__day' : 'input__night'"
                                v-model="form.date_of_birth"
                                type="date"
                                :placeholder="$t('message.date_of_birth')"
                                size="medium"
                                class="w-100"
                            >
                            </el-date-picker>
                        </div>
                    </el-col>
                    <!-- end col -->
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="success" size="medium" @click="update()" >
                     {{$t("message.save")}}
                </el-button>
                <el-button type="warning" size="medium" @click="close()" >
                    {{$t("message.close")}}
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import selectNation from "@/components/filters/inventory/select-nation";
import selectGender from "@/components/filters/inventory/select-gender";
export default {
    components: {
        selectNation,
        selectGender,
    },
    data() {
        return {
            form: {},
            loadChildren: false,
            childrenCreate: false,
            childrenUpdate: false,
        };
    },
    created() {
        this.fetchStaffChildren();
    },
    computed: {
        ...mapGetters({
            staffChildren: "profile/staff_children",
            mode: "MODE"

        }),
    },
    methods: {
        ...mapActions({
            getStaffChildren: "profile/getStaffChildren",
            addStaffChildren: "profile/addStaffChildren",
            updateStaffChildren: "profile/updateStaffChildren",
            deleteStaffChildren: "profile/deleteStaffChildren",
        }),
        submit() {
            this.form.staff_id = this.$route.params.id;
            if (this.form.staff_id) {
                this.addStaffChildren(this.form)
                    .then((res) => {
                        this.$alert(res);
                        if (res.status == 201) {
                            this.clearForm();
                            this.fetchStaffChildren();
                            this.close();
                        }
                    })
                    .catch((err) => {
                        this.$alert(err);
                    });
            }
        },

        fetchStaffChildren() {
            if (!this.loadChildren && this.$route.params.id) {
                this.loadChildren = true;
                this.getStaffChildren(this.$route.params.id)
                    .then((res) => {
                        this.loadChildren = false;
                    })
                    .catch((err) => {
                        this.loadChildren = false;
                    });
            }
        },
        close() {
            this.clearForm();
            this.childrenCreate = false;
            this.childrenUpdate = false;
        },
        clearForm() {
            for (var key in this.form) {
                this.form[key] = null;
            }
        },
        editStaffChildren(child) {
            this.form = JSON.parse(JSON.stringify(child));
            this.childrenUpdate = true;
        },
        opened(refs) {
            this.$refs[refs].opened();
        },
        open(refs) {
            this.$refs[refs].opened();
        },
        update() {
            this.form.staff_id = this.$route.params.id;
            this.form.child_id = this.form.id;
            if (this.form.staff_id && this.form.child_id) {
                this.loadChildren = true;
                this.updateStaffChildren(this.form)
                    .then((res) => {
                        this.$alert(res);
                        if (res.status == 201) {
                            this.clearForm();
                            this.fetchStaffChildren();
                            this.close();
                            this.loadChildren = false;
                        }
                    })
                    .catch((err) => {
                        this.$alert(err);
                        this.loadChildren = false;
                    });
            }
        },
        deleteChildren(child) {
            this.$confirm(
                "Вы действительно хотите это сделать?",
                "Предупреждение",
                {
                    confirmButtonText: "Да",
                    cancelButtonText: "Отмен",
                    type: "warning",
                }
            )
                .then(() => {
                    if (!this.loadChildren && this.$route.params.id) {
                        this.loadChildren = true;
                        let form = {
                            staff_id: this.$route.params.id,
                            child_id: child.id,
                        };
                        this.deleteStaffChildren(form)
                            .then((res) => {
                                this.loadChildren = false;
                                this.$alert(res);
                                if (res.status == 201) {
                                    this.fetchStaffChildren();
                                }
                            })
                            .catch((err) => {
                                this.loadChildren = false;
                            });
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "Операция отменена",
                    });
                });
        },
    },
};
</script>

